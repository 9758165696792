.removeBtn {
  position: relative;
  top: -20px;
  right: -20px;
  float: right;
}

.editBtn {
  position: relative;
  top: -20px;
  right: -17px;
  float: right;
}

.eyeBtn {
  position: relative;
  top: -20px;
  right: -23px;
  float: right;
}
